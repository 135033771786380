<!-- eslint-disable max-len -->
<template>
  <div class="page page--tierra">
    <Hero :title="$t('land.hero')" image="head-nuestra-tierra.jpg"></Hero>

    <div class="page__videos page__videos--tierra">
      <h2>{{$t('land.compromise')}}</h2>
      <p>{{$t('land.claim')}}</p>

      <div class="page__videos-block page__videos-block--green page__videos-block--nopad">
        <div class="pad">
          <h3>{{$t('land.subtitle-1')}}</h3>
          <h4>{{$t('land.claim-1')}}</h4>
          <p>{{$t('land.desc-1-1')}}</p>
          <p>{{$t('land.desc-1-2')}}</p>
        </div>
        <div class="bg" style="background-image: url(/images/nuestra-tierra-cercania.jpg)"></div>
      </div>

      <div class="page__videos-block page__videos-block--green page__videos-block--nopad">
        <div class="bg" style="background-image: url(/images/nuestra-tierra-impacto.jpg)"></div>
        <div class="pad">
          <h3>{{$t('land.subtitle-2')}}</h3>
          <h4>{{$t('land.claim-2')}}</h4>
          <p>{{$t('land.desc-2-1')}}</p>
          <p>{{$t('land.desc-2-2')}}</p>
          <p>{{$t('land.desc-2-3')}}</p>
        </div>
      </div>
    </div>

    <div class="page__videos page__videos--compromiso">
      <h2>{{$t('land.subcompromise')}}</h2>
      <p>{{$t('land.subclaim')}}</p>

      <div class="page__videos-block page__videos-block--grey page__videos-block--nopad">
        <div class="pad">
          <h3>{{$t('land.subtitle-3')}}</h3>
          <h4>{{$t('land.claim-3')}}</h4>
          <p>{{$t('land.desc-3-1')}}</p>
          <p>{{$t('land.desc-3-2')}}</p>
        </div>
        <div class="bg" style="background-image: url(/images/nuestra-tierra-el-aula-del-pan.jpg)"></div>
      </div>

      <div class="page__videos-block page__videos-block--grey page__videos-block--nopad">
        <div class="bg" style="background-image: url(/images/trompetas.jpeg)"></div>
        <div class="pad">
          <h3>{{$t('land.subtitle-4')}}</h3>
          <h4>{{$t('land.claim-4')}}</h4>
          <p>{{$t('land.desc-4-1')}}</p>
        </div>
      </div>
    </div>

    <ShopClaim/>

  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import ShopClaim from '@/components/ShopClaim.vue';

export default {
  name: 'Tierra',
  components: {
    Hero,
    ShopClaim,
  },
};
</script>

<style lang="scss">
.page--tierra {
  background: #F6F6F6;
}
.page__videos--tierra,
.page__videos--compromiso {
  h2 {
    margin-bottom: 12px;
  }
  > p {
    max-width: 900px;
    text-align: center;
    margin: 0 auto 40px;
  }
  .page__videos-block {
    h3 {
      font-weight: 100;
      font-size: 32px;
      margin: 0;
    }
    h4 {
      margin-top: 0;
      font-size: 24px;
      margin-bottom: 12px;
      font-weight: 900;
    }
  }
}
.page__videos--tierra {
  background: #d9e4d7;
  h2 {
    color: #70836C;
  }
}
</style>
